@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-components {
  @tailwind components;
}

@layer tailwind-utilities {
  @tailwind utilities;
}

/* Import PrimeReact theme after the Tailwind layers */
@import 'primereact/resources/themes/lara-light-blue/theme.css';

::selection {
  background: #e01b24;
  color: white;
}

body {
  @apply bg-background; /* Make sure 'bg-background' exists in Tailwind config */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: env(safe-area-inset-top); /* Adjust for iOS notch/Dynamic Island */
  padding-bottom: env(safe-area-inset-bottom);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
