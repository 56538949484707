/* css to check global css */
body {
  color: #1e1e1e;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 22px;
}
p {
  margin-bottom: 1rem;
}
.color-lightblack {
  color: #343539;
}
.color-bgred {
  background: #ca2f2e;
}
.color-textred {
  color: #ca2f2e;
}
.card-textbg {
  background: #f4f6fd;
}
.color-textgrey {
  color: #a0a0a0;
}
.color-text-lightgray {
  color: #515151;
}
.color-text-green {
  color: #3c881f;
}
.border-radius-50 {
  border-radius: 50px;
}
.custom-container {
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
.add-btn {
  background: #3c881f;
  border: 1px solid transparent;
  color: #fff;
  border-radius: 12px;
  padding: 0.7rem;
  font-weight: 600;
  font-size: 16px;
  justify-content: center;
  min-width: 116px;
  height: 41px;
}
.btn-green:hover,
.add-btn:hover {
  background: #e2eddd;
  border-color: #3c881f;
  color: #3c881f;
}
.btn-green:hover svg path,
.add-btn:hover svg path {
  fill: #3c881f;
}
.countBtn {
  padding: 0.5rem 0;
  height: 41px;
  border-radius: 12px;
  border: 1px solid #ececec;
  overflow: hidden;
}
.countBtn button {
  background: transparent;
  height: 41px;
  width: 30px;
}
#decrement-button {
  border-right: 1px solid #ececec;
  padding-inline: 0.25rem;
}
#increment-button {
  border-left: 1px solid #ececec;
  padding-inline: 0.25rem;
}
#decrement-button,
#increment-button,
.countBtn button + div {
  width: 30px;
}
.countBtn button:hover {
  background: #f4f6fd;
}
.wrapper-middle-section {
  border-radius: 50px;
}
.carousal img {
  border-radius: 50px;
}
.nav-innerwrap {
  display: flex !important;
}
.Itemswrap .item {
  max-width: 25%;
}
.Itemswrap .item-title {
  width: 70%;
  text-align: center;
}
.registercard-wrapper {
  background: linear-gradient(85.59deg, #367a1c 0.31%, #56cf28 99.69%);
  border-radius: 50px;
  color: #fff;
  min-height: 300px;
}
.wrap-similaritems {
  background: #dddde6;
}
.p-accordion .p-accordion-tab {
  border: 1px solid #efeff1;
  border-radius: 10px;
  margin-bottom: 1.5rem;
}
.p-accordion .p-accordion-header-link,
.p-accordion .p-accordion-content {
  background: transparent;
  color: #343539;
}
.p-accordion .p-accordion-content {
  font-size: 16px;
  font-weight: 500;
}
.p-accordion .p-accordion-header-link {
  font-weight: 600;
  font-size: 20px;
  align-items: baseline;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.p-accordion .p-accordion-header-text {
  line-height: 25px;
  max-width: 65%;
}
.p-accordion .p-accordion-header-link svg path {
  fill: #868794;
}
.wrap-helpsupport-page .active svg path {
  fill: #ca2f2e;
}
.tabcontent p {
  line-height: 25px;
  font-weight: 500;
}
.terms-wrapper ol li::marker {
  font-weight: bold;
}
.terms-wrapper ol li {
  margin-bottom: 1rem;
}
.p-popup {
  border-radius: 30px;
}
.p-popup.brand-popup .p-dialog-content,
.p-popup.brand-popup .p-dialog-header {
  background: #f6f5ff;
  padding: 1rem 1.5rem;
}
.p-popup.brand-popup .p-dialog-header button {
  margin: 0;
}
.p-popup.brand-popup .searchbar form {
  max-width: 100%;
  margin-bottom: 1rem;
}
.p-popup.brand-popup .brand-list-item label {
  color: #343539;
  font-weight: 500;
  font-size: 20px;
}
.p-popup.brand-popup .brand-list-item input[type="checkbox"] {
  width: 29px;
  height: 29px;
  border-radius: 4px;
  border-color: #dedee3;
}
.p-popup.brand-popup .p-dialog-header svg path {
  fill: #343539;
}
.p-popup.brand-popup .p-dialog-title {
  font-size: 30px;
  color: #343539;
  font-weight: 600;
}
.p-popup .p-dialog-header {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 0;
}
.p-popup .p-dialog-content {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.p-popup .p-dialog-header .p-dialog-header-icon {
  margin: 1rem 1.5rem 0;
}
.p-popup .p-dialog-header .p-dialog-header-icon svg path {
  fill: #868794;
}
input[type="checkbox"]:enabled:checked {
  background-color: #ca2f2e;
  color: #fff;
}
button.is-selected {
  border-color: #ca2f2e;
  background-color: #faeaea;
  color: #343539;
}
.grid-items .grid-item {
  width: 32%;
}
.p-sidebar-right .p-sidebar {
  box-shadow: -6px 4px 13.2px -1px #00000017;
  border: 1px solid #d2d2d2;
  width: 33.55rem;
}
.p-sidebar .p-sidebar-header {
  padding-inline: 2rem;
  z-index: 1;
  min-height: 100px;
}
.usersidebar .p-sidebar-header {
  border-bottom: 4px solid #dedee3;
}
.p-sidebar .p-sidebar-content {
  padding-inline: 2rem;
  z-index: 2;
  margin-bottom: 6rem;
}
.p-sidebar .userWrap {
  position: absolute;
  top: 18px;
}
.menuItems .m-item:not(:last-child) {
  margin-bottom: 1.5rem;
}
.m-item a {
  color: #000;
  font-size: 18px;
  font-weight: 500;
}
.m-item a:hover {
  color: #cd1318;
}
.m-item a:hover svg path {
  fill: #cd1318;
}
.m-item a svg {
  min-width: 36px;
  margin-right: 1rem;
}
.menuItems {
  border: 1px solid #efeff1;
  border-radius: 20px;
  margin-block: 1.5rem;
  padding: 1.5rem;
}
.btn-logout {
  color: #cd1318;
  border: 1px solid #efeff1;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  text-align: left;
  padding-inline: 1.5rem;
  padding-block: 1.25rem;
}
.btn-logout:hover {
  border-color: #cd1318;
}
.cart-count {
  position: absolute;
  top: -4px;
  color: #fff;
  background: #cd1318;
  height: 22px;
  width: 22px;
  border-radius: 100%;
  text-align: center;
  right: 5px;
  font-size: 12px;
  font-weight: 600;
}
.cart-slidesidebar .p-sidebar-header {
  min-height: 16px;
}
.cart-slidesidebar .titlewrap {
  position: absolute;
  top: 20px;
  padding-bottom: 1rem;
}
.cart-slidesidebar .item-counts {
  margin: 0 -2rem 1rem;
  padding-inline: 2rem;
}
.item-price {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.35rem;
}
.item-price .price {
  text-align: left;
  /* min-width: 75px; */
}
.btn-green {
  background: #3c881f;
  border: 1px solid transparent;
  color: #fff;
  border-radius: 12px;
  padding: 0.7rem;
  font-weight: 600;
  font-size: 16px;
  justify-content: center;
  height: 63px;
}
.cart-innerwrap {
  min-height: calc(100vh - 180px);
}
.cart-btnwrap {
  justify-content: space-between;
  position: absolute;
  bottom: 16px;
  width: inherit;
  left: 0;
  right: 0;
  padding-inline: 2rem;
}
.cart-item-table td {
  padding: 1rem 1.5rem 0;
  text-align: left;
}
.cart-item-table th {
  padding: 0.5rem 1.5rem;
  text-align: left;
}
.p-rating .p-rating-cancel-item {
  display: none;
}
.p-rating .p-rating-item .p-rating-icon.p-icon {
  width: 26px;
  height: 24px;
}
.p-rating .p-rating-item.p-focus {
  outline: none;
  box-shadow: none;
}
.p-rating .p-rating-item-active svg path {
  fill: #ca2f2e;
}
.p-rating .p-rating-item:hover .p-rating-icon {
  color: #ca2f2e;
}
.fliter-dropdown .filter-menuitem {
  font-size: 18px;
  font-weight: 600;
  padding: 0.4rem 1rem;
  cursor: pointer;
}
.fliter-dropdown .filter-menuitem:hover {
  background: #f4f5f6;
  border-radius: 4px;
}
.fliter-dropdown .filtermenu.active {
  opacity: 1;
}
.sidebar-filter-wrapper .accorditem {
  padding: 0.3rem 0;
  margin-inline: -10px;
}
.sidebar-filter-wrapper .accorditem:hover {
  background: #f6f5ff;
  border-radius: 4px;
}
.sidebar-filter-wrapper .parent-item:hover,
.sidebar-filter-wrapper .active {
  background: #f6f5ff;
  border-radius: 4px;
}
.sidebar-filter-wrapper .sub-item div {
  margin-top: 2px;
}

@media (min-width: 1200px) {
  .custom-container {
    max-width: 1200px;
  }
}
@media (min-width: 600px) {
  .custom-container {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (min-width: 768px) {
  .wrapBestseller-section .saler-card {
    width: 24%;
  }
}

img.h-16.object-contain {
  height: 8rem;
}
.profile-wrapper .field-group {
  margin-bottom: 1.75rem;
}
.profile-wrapper .field-group label {
  color: #343539;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0.7rem;
  display: block;
}
.profile-wrapper .field-group .field-group-value {
  color: #343539;
  font-size: 16px;
}
.btn-address:hover svg path {
  fill: #ca2f2e;
}
input[type="radio"]:checked,
input[type="checkbox"]:checked {
  accent-color: #3c881f;
  border-color: #3c881f;
}
.carousal button span svg {
  color: #000;
}
#pagination-ribbon {
  margin: 1rem 0;
}
.emptycart {
  height: calc(100vh - 180px);
}
.dot {
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 100%;
  margin-right: 5px;
}
.dot.dot-Deliverd {
  background: #269723;
}
.dot.dot-Return {
  background: #daa511;
}
.dot.dot-Cancelled {
  background: #ca2f2e;
}

.p-accordion .p-accordion-tab {
  background: white;
}
.cart-innerwrap tr {
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cart-innerwrap tr td {
  padding: 0.5rem;
}
.cart-slidesidebar.p-sidebar tr td:first-child {
  width: 60%;
}
.cart-slidesidebar.p-sidebar tr .price .text-black {
  font-size: 16px;
}
.cart-slidesidebar.p-sidebar tr .delete-btn svg {
  width: 14px;
  height: 20px;
}
.browser .cart-slidesidebar.p-sidebar tr .wrapcount {
  max-width: 72px;
}
.browser .cart-slidesidebar.p-sidebar #decrement-button svg {
  height: 3px;
}
.browser .cart-slidesidebar.p-sidebar tr .wrapcount svg {
  width: 12px;
  height: 10px;
}
.cart-slidesidebar.p-sidebar tr .countBtn {
  height: 32px;
}
.view-cart-container .p-accordion-header-text p {
  margin-bottom: 0;
  padding: 0;
}
.l-button button {
  height: 61px;
  box-shadow: none;
  border-radius: 10px;
  min-width: 200px;
  text-transform: capitalize;
}
.l-button button:hover {
  background: #e2eddd;
  border-color: #3c881f;
  color: #3c881f;
  box-shadow: none;
}
.parent-item .icon + .p-item-name {
  padding-left: 0.7rem;
}
.parent-item .p-item-name {
  padding-left: 1.2rem;
}
.breadcrumb .p-menuitem-link {
  font-weight: 500;
}
.breadcrumb .p-menuitem-link:hover span {
  color: #ca2f2e;
}
.filter-button button:hover {
  border-color: #3c881f;
  background: #e2eddd;
}
.brand-list-item .brand-item:hover {
  background-color: #e2eddd;
  border-radius: 4px;
}
.productcard:hover {
  box-shadow: 0px 0px 4px 5px #00000008;
  border-radius: 20px;
}
.detail-delivery-status span.label {
  font-weight: 600;
  margin-right: 0.35rem;
}
.detail-delivery-status > div {
  justify-content: space-between;
  margin-bottom: 0.35rem;
}
.wrapcategory .category-flex {
  flex-wrap: nowrap;
}
#profile-icon svg:hover path,
.wrapcategory .items:hover {
  color: #ca2f2e;
  fill: #ca2f2e;
}
.sortbar-wrapper .cart-count {
  width: 10px;
  height: 10px;
  top: 3px;
}
/* testimonial css start*/
.embla {
  overflow: hidden;
  width: 100%;
}

.embla__container {
  display: flex;
  gap: 1rem; /* Add space between slides */
}

.embla__slide {
  flex: 0 0 48%;
  margin-right: 1rem;
}
.testimonials-section .btn {
  position: absolute;
  top: 58%;
  height: 40px;
  width: 40px;
  background-color: #ca2f2e;
  color: #fff;
  box-shadow: 0px 4px 12.5px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}
.testimonials-section .embla__prev {
  left: 0;
  z-index: 99;
}
.testimonials-section .embla__next {
  right: 10px;
}
.embla__slide_mobile {
  flex: 0 0 auto;
  margin-right: 1rem;
}
/* testimonial css end */

.rich-text-content {
  display: block;
  white-space: pre-wrap; /* Preserve spaces and line breaks */
  word-break: keep-all; /* Prevent word breaks */
  overflow: hidden; /* Prevent overflow */
}

#webpack-dev-server-client-overlay {
  display: none !important;
}
.orderitem,
.cartwrapper .itemwrap {
  padding-bottom: .5rem;
  margin-bottom: .5rem;
  padding-top: .25rem;
  border-bottom: 1px solid #EFEFF1;
}
.orderitem:last-child,
.cartwrapper .itemwrap:last-child {
  border-bottom: none;
}

.cart-innerwrap .itemwrap {
  padding-bottom: .5rem;
  margin-bottom: .5rem;
  padding-top: .25rem;
  border-bottom: 1px solid #EFEFF1;
}

.cart-innerwrap .itemwrap:last-child {
  border-bottom: none;
}

.logo-container{
  padding: 30px;
}

.mainwrapper, 
.wrap-productlist,
.product-detail-page,
.view-cart-container,
.profile-pages,
.faqwrapper,
.staticpage,
.ourstory-page
{
  margin-top: 8rem !important;
}
.order-detail {
  margin-top: 2rem;
}
.wrap-helpsupport-page .staticpage {
  margin-top: 0 !important;
}
.profile-info-card .field-group .input-active {
  border: 1px solid #EFEFF1;
  width: 100%;
}
.profile-info-card .field-group .input-active:focus-visible {
  border-color: #9fd8ef;
}
.selected-address .address-card {
  border-color: #3C881F;
  background-color: #E2EDDD;
}